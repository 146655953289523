<template>
  <!-- Main content -->
  <section class="content">
      <div class="btn-box">
        <el-form :inline="true">
            <el-form-item label="用户user_id：">
                <el-input v-model="user_id" placeholder="请输入user_id"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="seach(1)">搜索</el-button></el-button> 
            </el-form-item>
            </el-form>
      </div>
    <el-table
        v-loading="loading"
        :data="userList"
        @sort-change="sort"
        border>
      <el-table-column label="id" prop="id"></el-table-column>
      <el-table-column label="用户user_id(user_key)">
        <template slot-scope="scope">
          {{scope.row.user_id}} ( {{scope.row.user_key}} )
        </template>
</el-table-column>
<el-table-column label="总夺宝次数" prop="loot_times" sortable="custom"></el-table-column>
<el-table-column label="已使用夺宝次数" sortable="custom" prop="join_times">
</el-table-column>
<el-table-column label="状态">
    <template slot-scope="scope">
          {{scope.row.status | status}}
        </template>
</el-table-column>
<el-table-column label="操作">
    <template slot-scope="scope" v-if="scope.row.status == 2">
        <el-button type="danger" size="mini" @click="manage(scope.row.id)">解除风控</el-button>
    </template>
</el-table-column>
</el-table>
<el-pagination background layout="total, sizes,prev, pager, next,jumper" :total="total" @size-change="sizeChange" @current-change="currentChange">
</el-pagination>


</section>
</template>
<script>
    import * as api from '@/config/api'
    export default {
        name: 'activeityUser',
        data() {
            return {
                dialog: false,
                loading: true,
                userList: [],
                total: 0,
                pagesize: 10,
                page: 1,

                id: '',
                act_version: '',
                status: '',

                user_id: '',
                rank: ''
            }
        },
        filters: {
            status(val) {
                switch (Number(val)) {
                    case 1:
                        return '正常'
                        break;
                    case 2:
                        return '触发风控被禁'
                        break;
                }
            },
            dateTime(val) {
                let day = new Date(Number(val) * 1000)
                if (Number(val) === 0) {
                    return ''
                }
                return day.getFullYear() + '-' + `${(day.getMonth() + 1) < 10 ? '0' + (day.getMonth() + 1) : (day.getMonth() + 1)}` + '-' + `${day.getDate()<10?'0'+day.getDate():day.getDate()}` + ' ' + `${day.getHours()<10?'0'+day.getHours():day.getHours()}` + ':' + `${day.getMinutes()<10?'0'+day.getMinutes():day.getMinutes()}` + ':' + `${day.getSeconds()<10?'0'+day.getSeconds():day.getSeconds()}`
            }
        },
        mounted() {
            this.getActUser()
        },
        methods: {
            getActUser(page, size) {
                let sort =
                    this.rank ? this.rank.order == "descending" ? 'desc' : this.rank.order == "ascending" ? 'asc' : '' : ''
                api.getActUser({
                    user_id: this.user_id,
                    page: page ? page : this.page,
                    pagesize: size ? size : this.pagesize,
                    order: this.rank ? this.rank.prop : '',
                    sort: sort
                }, res => {
                    this.userList = res.data.list
                    this.total = Number(res.data.count)
                    this.loading = false
                })
            },
            sizeChange(val) {
                this.pagesize = val
                this.getActUser()
            },
            currentChange(val) {
                this.page = val
                this.getActUser()
            },

            manage(id) {
                api.postActUser({
                    id: id,
                }, res => {
                    this.getActUser()
                })
            },
            seach(page, size) {
                this.getActUser('', page, size)
            },
            sort(rank) {
                this.rank = rank
                this.getActUser()
            }
        }
    }
</script>
<style scoped>
    .btn-box {
        text-align: left;
        margin-bottom: 20px;
    }
</style>